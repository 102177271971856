import { DistributionModel } from "@/domain/model/distribution";
import { DistributorTypeModel } from "@/domain/model/distributor_type";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

interface MsSelectProps {
  label?: string;
  value?: number | string;
  onChange?:
    | ((event: SelectChangeEvent<any>, child: React.ReactNode) => void)
    | undefined;
  items?: DistributionModel[] | DistributorTypeModel[] | [];
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
}

export function MsSelect(props: MsSelectProps) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl size={props.size} fullWidth>
        <InputLabel id="demo-simple-select-autowidth-label">
          {props.label}
        </InputLabel>
        <Select
          disabled={props.disabled}
          size={props.size}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={props.value}
          label={props.label}
          onChange={props.onChange}
        >
          {props.items?.map((item: any) => (
            <MenuItem value={item.ID} key={item.ID}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
