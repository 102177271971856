// import { Axios } from "../axios";
import { DistributionInt } from "@repository/interface/distribution";
import { BaseRepo } from "@/repository/base_repo";
import { DistributionModel } from "@/domain/model/distribution";
import { TrackerModel } from "@/domain/model/tracker";

export class Distribution implements DistributionInt {
  sendQrProduct(data: any): any {
    return new Promise((resolve, reject) => {
      BaseRepo.post("/distribution/qr/send", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchDistribution(data: any): any {
    return new Promise((resolve, reject) => {
      BaseRepo.post("/distribution/qr/fetch", data)
        .then((result) => {
          let distributionObj = DistributionModel.fromJsonList(
            result.data.result
          );
          let pagination = result.data.pagination;
          let distribution = {
            distribution: distributionObj,
            pagination: pagination,
          };
          resolve(distribution);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getDistribution(data: any): any {
    return new Promise((resolve, reject) => {
      BaseRepo.post("/distribution/qr/get", data)
        .then((result) => {
          let getDistributionObj = {
            distribution: DistributionModel.fromJson(result.data),
            sender: TrackerModel.fromJson(result.data.sender),
            receiver: TrackerModel.fromJson(result.data.receiver),
          };
          resolve(getDistributionObj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
