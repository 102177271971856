import { API_URL_AGGREGATION } from "@/domain/constanta/setting";
import axiosClient from "@/infrastructure/axios/axios";

export class BaseRepoMsAggregation {
  public static get(url: string, data: any) {
    return axiosClient({
      baseURL: `${API_URL_AGGREGATION}`,
      method: "GET",
      url: `${url}${data}`,
    });
  }
}
