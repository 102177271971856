import { Box, CircularProgress, Fade, LinearProgress } from "@mui/material";

interface MsLoadingProps {
  show?: boolean;
  variant?: "linear" | "circular";
}

export function MsLoading(props: MsLoadingProps) {
  return (
    <Box height={2}>
      {props.variant === "circular" ? (
        <Fade in={props.show} unmountOnExit>
          <CircularProgress />
        </Fade>
      ) : (
        <Fade in={props.show} unmountOnExit>
          <LinearProgress />
        </Fade>
      )}
    </Box>
  );
}
