//react
import React from "react";

//material
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

//icon
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface QrListProps {
  show?: boolean;
  items?: any | [];
  product?: { product_id: number; name: string } | undefined;
  handleRemoveQr: (
    index: React.MouseEventHandler<HTMLButtonElement>
  ) => void | undefined;
}

export function QrList(props: QrListProps) {
  return (
    <div>
      {props.items.length > 0 ? (
        <p>
          {props.product?.name ? (
            <>
              Product: <b>{props.product?.name}</b> &nbsp; | &nbsp;
            </>
          ) : (
            ""
          )}{" "}
          Product Count: <b>{props.items.length}</b>
        </p>
      ) : (
        ""
      )}

      {props.items?.map((item: any, index: any) => (
        <Grid container>
          <Grid item md={11} sm={11} lg={11}>
            <Card
              key={index}
              variant="outlined"
              sx={{
                py: 1,
                px: 1,
                my: 0.5,
                maxWidth: "100%",
                border: 1,
                borderColor: "#8c8c8b",
              }}
            >
              <Grid container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  md={10}
                  sm={10}
                  lg={10}
                >
                  <Box
                    sx={{ alignItems: "center" }}
                    display={"flex"}
                    justifyContent="start"
                    alignItems={"center"}
                  >
                    <Typography variant="body2" color="text.secondary">
                      UNIQ ID: <b>{item}</b>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item md={1} sm={1} lg={1}>
            <Box display={"flex"} justifyContent="end" alignSelf={"center"}>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => props.handleRemoveQr(index)}
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
