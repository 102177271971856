import { API_URL_SECURE } from "@/domain/constanta/setting";
import axiosClient from "@/infrastructure/axios/axios";

export class BaseRepoMsSecure {
  public static async get(url: string, data: any) {
    return await axiosClient({
      baseURL: `${API_URL_SECURE}`,
      method: "GET",
      url: `${url}${data}`,
    });
  }
}
