import "./App.css";
import { Route, Routes } from "react-router-dom";

import { MainView } from "@delivery/view/MainView";

import { Distribution } from "@delivery/view/Distribution/Distribution";
import { SendProduct } from "@delivery/view/SendProduct/SendProduct";
import { Tracker } from "@delivery/view/Tracker/Tracker";
import { Login } from "@delivery/view/Login/Login";
import { Distributor } from "@delivery/view/Distributor/Distributor";
import { FraudDetector } from "@delivery/view/FraudDetector/FraudDetector";
import { SendProductSenderReceiver } from "@delivery/view/SendProduct/SendProductSenderReceiver";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<MainView />}>
        <Route
          path="/admin/send-product"
          element={<SendProductSenderReceiver />}
        />
        <Route path="/admin/tracker" element={<Tracker />} />
        <Route path="/admin/distribution" element={<Distribution />} />
        <Route path="/admin/distributor" element={<Distributor />} />
        <Route path="/admin/fraud-detector" element={<FraudDetector />} />
      </Route>
    </Routes>
  );
}

export default App;
