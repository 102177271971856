//material
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

//hook
import useFraudDetector from "@/store/hook/fraud_detector";

//component
import { TableDistributorFraud } from "@delivery/view/FraudDetector/TableDistributorFraud";

//lottie
import Lottie from "lottie-react";
import Radar from "@/delivery/assets/98501-radar.json";

export function FraudDetector() {
  const {
    loading,
    distributionFraud,
    page,
    totalCount,
    rowsPerPage,
    handleOnPageChange,
    handleRowsPerPageChange,
  } = useFraudDetector();

  return (
    <Grid>
      {distributionFraud?.length === 0 ? (
        <Grid
          container
          style={{ minHeight: "100vh", border: "3px", boxSizing: "border-box" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item sx={{ width: "350px" }}>
            <Lottie animationData={Radar} loop={true} />
            <Typography
              align="center"
              sx={{ mt: 2, fontWeight: "light" }}
              variant="h5"
            >
              No Fraud Detected
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container>
            <Box
              height={"100%"}
              display={"flex"}
              justifyContent="start"
              alignItems={"center"}
            >
              <Typography sx={{ fontWeight: "bold" }} variant="h5">
                Distribution Fraud
              </Typography>
            </Box>
            {/* search field / filter located */}
          </Grid>
          <Grid sx={{ py: 4 }}>
            <TableDistributorFraud
              loading={loading}
              page={page}
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              onPageChange={handleOnPageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              items={distributionFraud}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
