// React
import React from "react";
import { useEffect, useState } from "react";

//domain
import { FraudDetectorModel } from "@/domain/model/fraud_detector";

//repository
import { FraudDetector } from "@/repository/api/fraud_detector";

const useFraudDetector = () => {
  //object
  let fraudDetectorObj = new FraudDetector();

  //loading
  const [loading, setLoading] = useState(false);

  //
  const [distributionFraud, setDistributionFraud] =
    useState<FraudDetectorModel[]>();

  //pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const handleOnPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // change page
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    //change page
    let perPage = parseInt(event.target.value);
    //
    setRowsPerPage(perPage);
  };

  // --- END PAGINATION FUNCTION ---

  useEffect(() => {
    setLoading(true);
    let params = {
      distributor_uuid: "43b50552-9bc3-488c-89c5-c4f7a79fde5d",
      distribution_unique_id: "KI2N22",
    };

    fraudDetectorObj
      .fetchDistributionFraud(params)
      .then((result: any) => {
        setDistributionFraud(result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return {
    page,
    loading,
    totalCount,
    rowsPerPage,
    distributionFraud,
    handleOnPageChange,
    handleRowsPerPageChange,
  };
};

export default useFraudDetector;
