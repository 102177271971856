//component
import Maps from "@/delivery/components/Maps/Maps";
import { MsTextField } from "@/delivery/components/TextField/TextField";

//model
import { DistributorModel } from "@/domain/model/distributor";

//utility
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";

interface DialogDetailDistributorProps {
  show: boolean;
  onClickClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  items?: DistributorModel;
}

export function DialogDetailDistributor(props: DialogDetailDistributorProps) {
  const { show, onClickClose, items } = props;

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      scroll={"paper"}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Detail Distributor</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item lg={6} md={6} sm={6}>
            <Maps lat={items?.latitude} long={items?.longitude} />
          </Grid>
          <Grid item lg={6} md={6} sm={6}>
            <Box sx={{ px: 1, py: 1 }}>
              <MsTextField
                variant={"standard"}
                disabled
                label="UUID"
                value={items?.UUID}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Name"
                value={items?.name}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Full Address"
                value={items?.fullAddress}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Type"
                value={items?.distributorType?.name}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Created At"
                value={changeTimeFormat(items?.createdAt)}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClickClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
