import { QRProductModel } from "@/domain/model/qr_product";
import { QrProductInt } from "@repository/interface/qr_product";
import { BaseRepoMsSecure } from "@repository/base_repo_ms_secure";

export class QrProduct implements QrProductInt {
  checkByUUID(data: any) {
    return new Promise<any>((resolve, reject) => {
      BaseRepoMsSecure.get("/v2/qr/", data)
        .then((res) => {
          let resProduct = QRProductModel.fromJson(res.data);
          resolve(resProduct);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
