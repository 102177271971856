import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { MAP_API_KEY } from "@/domain/constanta/setting";

const containerStyle = {
  width: "100%",
  height: "400px",
};

interface MapsDynamicProps {
  zoomVal?: number;
  onSetCoordinate: (lat: any, long: any) => void;
}

interface Coordinate {
  latitude: number | undefined | any;
  longitude: number | undefined | any;
}

function MapsDynamic(props: MapsDynamicProps) {
  const { zoomVal, onSetCoordinate } = props;
  //zoom
  const [zoom, setZoom] = useState<number | undefined>(14);

  const [coordinate, setCoordinate] = useState<Coordinate>({
    latitude: -8.6347376,
    longitude: 115.2496424,
  });

  // center maps
  const center = {
    lat: coordinate.latitude,
    lng: coordinate.longitude,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    map.setZoom(15);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  //send coodinates
  const handleSetCoordinate = (lat: any, long: any) => {
    setCoordinate({
      latitude: lat,
      longitude: long,
    });
    onSetCoordinate(lat, long);
  };

  return isLoaded ? (
    <GoogleMap
      onClick={(ev) => {
        handleSetCoordinate(ev.latLng?.lat(), ev.latLng?.lng());
      }}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker
        key={"marker_1"}
        position={{
          lat: coordinate.latitude,
          lng: coordinate.longitude,
        }}
      />
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MapsDynamic);
