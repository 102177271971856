//react
import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

//material
import {
  Box,
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Grid,
} from "@mui/material";

//icon
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SendIcon from "@mui/icons-material/Send";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import RadarOutlinedIcon from "@mui/icons-material/RadarOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AccessPermission } from "@/infrastructure/utils/access_permission";

const drawerWidth = 240;

interface DrawerProps {
  label: string;
  link: string;
  icon: any;
  children?: DrawerProps[] | undefined;
  rules: string[];
}

export function DrawerSide() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClick = (item: any) => {
    if (item.children) {
      setOpen(!open);
    }
  };

  const handleClickSubMenu = (item: any) => {
    if (item.link === location.pathname) {
      setOpen(true);
      return;
    }
    setOpen(false);
  };

  let menu: DrawerProps[] = [
    {
      label: "Send Product",
      link: "/admin/send-product",
      icon: <SendIcon />,
      rules: ["ms-distribution.access_send_qr"],
    },
    {
      label: "Tracker",
      link: "/admin/tracker",
      icon: <GpsFixedOutlinedIcon />,
      rules: [
        "ms-distribution.access_track_qr",
        "ms-distribution.access_all_track_qr",
      ],
    },
    {
      label: "Distribution",
      link: "/admin/distribution",
      icon: <LocalShippingIcon />,
      rules: ["ms-distribution.access_all_distribution"],
    },
    {
      label: "Distributor",
      link: "/admin/distributor",
      icon: <RecordVoiceOverIcon />,
      rules: [
        "ms-distribution.access_create_distributor",
        "ms-distribution.access_fetch_distributor",
        "ms-distribution.access_get_distributor",
      ],
    },
    {
      label: "Fraud Detector",
      link: "/admin/fraud-detector",
      icon: <RadarOutlinedIcon />,
      rules: [
        "ms-distribution.access_anti_fraud",
        "ms-distribution.access_cross_selling",
      ],
    },
  ];

  return (
    <Drawer
      open={false}
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      {/* <img
        src="https://urbangroup.co.id/wp-content/uploads/2020/03/urban-company-logo-kecil.png"
        width={"200px"}
        alt={"Urban Company"}
      /> */}
      <Box sx={{ overflow: "auto" }}>
        <List>
          {menu.map((item, index) => (
            <NavLink
              key={index}
              onClick={() => handleClick(item)}
              style={{
                backgroundColor: "#000000",
                color: "#000000",
                textDecoration: "none",
              }}
              to={item.link}
            >
              {AccessPermission.checkAccess(item.rules) === true ? (
                <ListItem disablePadding>
                  <ListItemButton selected={item.link === location.pathname}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{item.label}</ListItemText>
                    {item.children ? (
                      <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
              ) : (
                ""
              )}

              {item.children?.map((itemSub, indexSub) => (
                <Collapse in={open}>
                  <Grid style={{ borderLeft: "#000000", border: "1px" }}>
                    <NavLink
                      key={indexSub}
                      onClick={() => handleClickSubMenu(itemSub.link)}
                      style={{
                        backgroundColor: "#000000",
                        color: "#000000",
                        textDecoration: "none",
                      }}
                      to={itemSub.link}
                    >
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={itemSub.link === location.pathname}
                          sx={{ pl: 4 }}
                        >
                          <ListItemIcon>{itemSub.icon}</ListItemIcon>
                          <ListItemText>{itemSub.label}</ListItemText>
                        </ListItemButton>
                      </List>
                    </NavLink>
                  </Grid>
                </Collapse>
              ))}
            </NavLink>
          ))}
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
}
