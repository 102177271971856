import { BaseModel } from "@domain/model/base_model";

export class TrackerModel extends BaseModel {
  name: string;
  status: string;
  latitude: number;
  longitude: number;
  notes: string;
  userID: string;
  address: string;
  fullAddress: string;
  nationalID: number;
  nationalCode: string;
  nationalName: string;
  provinceID: number;
  provinceName: string;
  cityID: number;
  cityName: string;
  districtID: number;
  districtName: string;
  villageID: number;
  villageName: string;
  zipCode: number;
  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    name: string,
    status: string,
    latitude: number,
    longitude: number,
    notes: string,
    userID: string,
    address: string,
    fullAddress: string,
    nationalID: number,
    nationalCode: string,
    nationalName: string,
    provinceID: number,
    provinceName: string,
    cityID: number,
    cityName: string,
    districtID: number,
    districtName: string,
    villageID: number,
    villageName: string,
    zipCode: number
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.ID = ID;
    this.UUID = UUID;
    this.createdAt = createdAt;
    this.updateAt = updateAt;
    this.name = name;
    this.status = status;
    this.latitude = latitude;
    this.longitude = longitude;
    this.notes = notes;
    this.userID = userID;
    this.address = address;
    this.fullAddress = fullAddress;
    this.nationalID = nationalID;
    this.nationalCode = nationalCode;
    this.nationalName = nationalName;
    this.provinceID = provinceID;
    this.provinceName = provinceName;
    this.cityID = cityID;
    this.cityName = cityName;
    this.districtID = districtID;
    this.districtName = districtName;
    this.villageID = villageID;
    this.villageName = villageName;
    this.zipCode = zipCode;
  }

  public static fromJsonList(data: any): TrackerModel[] | any {
    try {
      let listTracker: TrackerModel[];
      listTracker = [];
      for (let x = 0; x < data.length; x++) {
        listTracker.push(TrackerModel.fromJson(data));
      }
    } catch {
      return null;
    }
  }

  public static fromJson(data: any): TrackerModel | any {
    try {
      return new TrackerModel(
        data.id,
        data.uuid,
        data.created_at,
        data.updated_at,
        data.name,
        data.status,
        data.latitude,
        data.longitude,
        data.notes,
        data.user_id,
        data.address,
        data.full_address,
        data.national_id,
        data.national_code,
        data.national_name,
        data.province_id,
        data.province_name,
        data.city_id,
        data.city_name,
        data.district_id,
        data.district_name,
        data.village_id,
        data.village_name,
        data.zip_code
      );
    } catch {
      return null;
    }
  }

  public static senderReceiver(data: any) {
    try {
      let senderReceiver: [] | any;
      senderReceiver = [];
      let senderReceiverObj: any | {};
      senderReceiverObj = {};

      for (let x = 0; x < data.length; x++) {
        senderReceiverObj = {
          UUID: data[x].uuid,
          createdAt: data[x].created_at,
          sender: TrackerModel.fromJson(data[x].distribution.sender),
          receiver: TrackerModel.fromJson(data[x].distribution.receiver),
        };

        senderReceiver.push(senderReceiverObj);
      }
      return senderReceiver;
    } catch {
      return null;
    }
  }
}
