//material
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

//hook
import useDistribution from "@store/hook/distribution";

//component
import { TableDistribution } from "@delivery/view/Distribution/TableDistribution";
import { DialogDetailDistribution } from "@delivery/view/Distribution/DialogDetailDistribution";
import { useEffect } from "react";

export function Distribution() {
  const {
    page,
    totalCount,
    rowsPerPage,
    handleChangePage,
    handleRowsPerPageChange,
    distributions,
    distribution,
    loading,
    handleDetailDistribution,
    showDialogDistribution,
    setShowDialogDistribution,
  } = useDistribution();

  return (
    <Grid>
      <Typography sx={{ fontWeight: "bold" }} variant="h5">
        Distribution
      </Typography>
      <Grid sx={{ pt: 4 }}>
        <TableDistribution
          onPageChange={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
          onRowsPerPageChange={handleRowsPerPageChange}
          onDetailDistribution={(UUID) => handleDetailDistribution(UUID)}
          loading={loading}
          items={distributions}
        />
        <DialogDetailDistribution
          items={distribution}
          show={showDialogDistribution}
          onClickClose={() => setShowDialogDistribution(false)}
        />
      </Grid>
    </Grid>
  );
}
