//material design
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Stack,
  TablePagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

//component
import { MsLoading } from "@/delivery/components/MsLoading/MsLoading";

//utility
import shortCharacter from "@/infrastructure/utils/short_character";

///model
import { DistributorModel } from "@/domain/model/distributor";

function createColumn(id: string | number, name: string) {
  return { id, name };
}

const columns = [
  createColumn(1, "UUID"),
  createColumn(2, "Name"),
  createColumn(3, "Address"),
  createColumn(5, "Action"),
];

interface TableDistributionProps {
  items?: DistributorModel[];
  loading?: boolean;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onDetailDistributor: (UUID: String) => void;
}

export function TableDistributor(props: TableDistributionProps) {
  const {
    items,
    totalCount,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
  } = props;
  return (
    <div>
      <MsLoading variant="linear" show={props.loading} />
      <TableContainer variant="outlined" component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#eceef0" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items)
              ? items?.map((item: any) => (
                  <TableRow key={item.ID}>
                    <TableCell align="left">
                      {shortCharacter(item.UUID, 13)}
                    </TableCell>
                    <TableCell align="left">{item.name}</TableCell>
                    <TableCell align="left">
                      {item.districtName}, {item.provinceName}
                    </TableCell>
                    <TableCell align="left">
                      <LoadingButton
                        sx={{ mr: 1 }}
                        size="small"
                        disableElevation
                        color="secondary"
                        variant="outlined"
                        onClick={() => props.onDetailDistributor(item.UUID)}
                      >
                        Detail
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Stack sx={{ py: 2 }} spacing={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={totalCount}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Stack>
      </Grid>
    </div>
  );
}
