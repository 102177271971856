// react
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//infrastructure
import { getProfile, removeToken } from "@/infrastructure/utils/auth_utils";

const useAppBar = () => {
  let navigator = useNavigate();
  const [profile, setProfile] = useState<any>();
  const [dialogConfirmLogout, setDialogConfirmLogout] = useState(false);

  useEffect(() => {
    let dataProfile: any = getProfile();
    setProfile(dataProfile);
  }, []);

  //handle logout
  const handleLogout = () => {
    removeToken();
    navigator("/login");
  };

  return {
    profile,
    handleLogout,
    dialogConfirmLogout,
    setDialogConfirmLogout,
  };
};

export default useAppBar;
