//react
import { useState } from "react";

//icon
import MapIcon from "@mui/icons-material/Map";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

//material
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";

//vertical timeline package
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

//infrastructure
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";

//delivery
import { DialogMapsDetail } from "@delivery/view/Tracker/DialogMapsDetail";

interface TimeLineTrackerProps {
  items?: [] | any;
}

export function TimeLineTracker(props: TimeLineTrackerProps) {
  const [showMaps, setShowMaps] = useState<boolean>(false);
  const [coordinate, setCoordinate] = useState({ lat: "", long: "" });

  const handleDetailMaps = (lat: string, long: string) => {
    setCoordinate({ lat: lat, long: long });
    setShowMaps(true);
  };

  return (
    <div>
      {props.items.length !== 0 ? (
        <VerticalTimeline layout="1-column-left" lineColor="#d4d5d6">
          <VerticalTimelineElement
            position={"right"}
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(22, 77, 90, 1)", color: "#fff" }}
            contentArrowStyle={{
              borderRight: "7px solid  rgb(22, 77, 90, 1)",
            }}
            date={changeTimeFormat(props.items[0]?.createdAt)}
            iconStyle={{ background: "#164D5A", color: "#fff" }}
            icon={<LocalShippingOutlinedIcon />}
          >
            <Grid container>
              <Grid item md={10}>
                <h3 className="vertical-timeline-element-title">
                  {props.items[0]?.sender.name}
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  {props.items[0]?.sender.villageName},{" "}
                  {props.items[0]?.sender.provinceName}
                </h5>
                <p>{props.items[0]?.sender.fullAddress}</p>
              </Grid>
              <Grid item md={2}>
                <Box
                  display={"flex"}
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <IconButton
                    onClick={() =>
                      handleDetailMaps(
                        props.items[0]?.sender.latitude,
                        props.items[0]?.sender.longitude
                      )
                    }
                    title="Open Map"
                    sx={{ color: "white" }}
                    aria-label="delete"
                  >
                    <MapIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </VerticalTimelineElement>

          {props.items?.map((item: any, index: any) => (
            <VerticalTimelineElement
              key={index}
              position={"right"}
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(22, 77, 90, 1)", color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(22, 77, 90, 1)",
              }}
              date={changeTimeFormat(item.createdAt)}
              iconStyle={{ background: "#164D5A", color: "#fff" }}
              icon={<LocalShippingOutlinedIcon />}
            >
              <Grid container>
                <Grid item md={10}>
                  <h3 className="vertical-timeline-element-title">
                    {item.receiver.name}
                  </h3>
                  <h5 className="vertical-timeline-element-subtitle">
                    {item.receiver.villageName}, {item.receiver.provinceName}
                  </h5>

                  <p>{item.receiver.fullAddress}</p>
                </Grid>
                <Grid item md={2}>
                  <Box
                    display={"flex"}
                    height={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <IconButton
                      onClick={() =>
                        handleDetailMaps(
                          item.receiver.latitude,
                          item.receiver.longitude
                        )
                      }
                      title="Open Map"
                      sx={{ color: "white" }}
                      aria-label="delete"
                    >
                      <MapIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      ) : (
        ""
      )}

      <DialogMapsDetail
        onClose={() => setShowMaps(false)}
        items={coordinate}
        show={showMaps}
      />
    </div>
  );
}
