import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import React from "react";
import { InputLabel } from "@mui/material";

interface TextFieldQrProps {
  helperText?: string;
  label?: string;
  onKeyEnter?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  variant?: "outlined" | "standard" | "filled" | undefined;
  disabled?: boolean;
  spaceY?: boolean;
  spaceX?: boolean;
  size?: "small" | "medium" | undefined;
  type?: "text" | "number" | "password";
}

export function MsTextField(props: TextFieldQrProps) {
  return (
    <FormControl
      sx={{ py: props.spaceY ? 0.5 : null, px: props.spaceX ? 0.5 : null }}
      size={props.size ? props.size : "medium"}
      fullWidth
    >
      {/* <InputLabel htmlFor="component-outlined">UUID</InputLabel> */}
      <TextField
        type={props.type}
        margin="dense"
        variant={props.variant ? props.variant : "outlined"}
        size={props.size ? props.size : "medium"}
        id="component-outlined"
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyEnter}
        disabled={props.disabled}
      />
      {props.helperText ? (
        <FormHelperText id="component-helper-text">
          {props.helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </FormControl>
  );
}
