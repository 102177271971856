//material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItemText,
  ListItemButton,
  Grid,
  Pagination,
  Box,
} from "@mui/material";

//model
import { DistributorModel } from "@/domain/model/distributor";

//delivery
import { MsLoading } from "@/delivery/components/MsLoading/MsLoading";

//constanta
import { PER_PAGE_DIALOG_DISTRIBUTOR } from "@/domain/constanta/setting";

interface DialogDistributorProps {
  show: boolean;
  onClose?: () => void;
  dataDistributor: DistributorModel[] | undefined | any;
  onSelect: (item: any) => void;
  onChangePagePagination?: (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => void | undefined;
  page?: number;
  loading?: boolean;
}

export function DialogDistributor(props: DialogDistributorProps) {
  const total_rows = props.dataDistributor?.pagination.total_rows;
  const count = Math.ceil(total_rows / PER_PAGE_DIALOG_DISTRIBUTOR);

  return (
    <Grid>
      <Dialog fullWidth open={props.show} maxWidth={"sm"}>
        <DialogTitle>{"Choose Distributor"}</DialogTitle>
        <DialogContent>
          <MsLoading show={props.loading} variant={"linear"} />
          <DialogContentText id="alert-dialog-slide-description">
            <List sx={{ pt: 0 }}>
              {props.dataDistributor?.distributor.map(
                (item: any, index: number) => (
                  <ListItemButton
                    sx={{ border: 1, borderRadius: 2, my: 1 }}
                    onClick={() => props.onSelect(item)}
                    key={index}
                  >
                    <Grid container>
                      <Grid item md={6} sm={6} lg={6}>
                        <ListItemText>
                          <b>{item.name}</b>
                        </ListItemText>
                      </Grid>
                      <Grid item md={6} sm={6} lg={6}>
                        <ListItemText>
                          {item.cityName}, {item.provinceName}
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                )
              )}
            </List>
            <br />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                page={props.page}
                onChange={props.onChangePagePagination}
                count={count}
                variant="outlined"
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
