//react
import React, { useState } from "react";

//component
import { MsButton } from "@/delivery/components/Button/Button";
import MapsDynamic from "@/delivery/components/MapsDynamic/MapsDynamic";
import { MsSelect } from "@/delivery/components/Select/Select";
import { MsTextField } from "@/delivery/components/TextField/TextField";

//model
import { DistributorModel } from "@/domain/model/distributor";
import { DistributorTypeModel } from "@/domain/model/distributor_type";

//material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";

interface DialogCreateDistributorProps {
  show: boolean;
  onClickClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSave: (
    name: string,
    type: string,
    latitude: string,
    longitude: string,
    upline_distributor_id: string
  ) => void;
  itemsType?: DistributorTypeModel[];
  itemDistributors?: DistributorModel[];
  loading?: boolean;
}

export function DialogCreateDistributor(props: DialogCreateDistributorProps) {
  const {
    show,
    onClickClose,
    onClickSave,
    itemsType,
    itemDistributors,
    loading,
  } = props;

  //create data distributor
  const [createDistributor, setCreateDistributor] = useState({
    name: "",
    type: "",
    upline_distributor_id: "",
  });
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const handleSetCoordinate = (lat: any, long: any) => {
    setLongitude(long);
    setLatitude(lat);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      scroll={"paper"}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Create Distributor</DialogTitle>
      <DialogContent>
        <Box sx={{ px: 2, py: 2 }}>
          <Grid container>
            <Grid item md={6} lg={6} sm={6}>
              {/* maps here */}
              <MapsDynamic
                onSetCoordinate={(lat, long) => handleSetCoordinate(lat, long)}
              />
              *click location on maps
            </Grid>

            <Grid sx={{ px: 2 }} item md={6} lg={6} sm={6}>
              <MsSelect
                value={createDistributor.type}
                size="small"
                label="Distributor Type"
                items={Array.isArray(itemsType) ? itemsType : []}
                onChange={(e: SelectChangeEvent<any>, child: React.ReactNode) =>
                  setCreateDistributor({
                    ...createDistributor,
                    type: e.target.value,
                  })
                }
              />
              <MsTextField
                value={createDistributor.name}
                label="Name"
                variant="outlined"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCreateDistributor({
                    ...createDistributor,
                    name: e.target.value,
                  })
                }
              />
              <MsSelect
                items={Array.isArray(itemDistributors) ? itemDistributors : []}
                value={createDistributor.upline_distributor_id}
                label="Upline Distributor"
                size="small"
                onChange={(e: SelectChangeEvent<any>, child: React.ReactNode) =>
                  setCreateDistributor({
                    ...createDistributor,
                    upline_distributor_id: e.target.value,
                  })
                }
              />
              <Grid container>
                <Grid pr={0.5} item md={6} xs={6} lg={6}>
                  <MsTextField
                    disabled
                    value={latitude}
                    type="number"
                    label="Latitude"
                    variant="outlined"
                    size="small"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setLatitude(e.target.value)
                    }
                  />
                </Grid>
                <Grid pl={0.5} item md={6} xs={6} lg={6}>
                  <MsTextField
                    disabled
                    value={longitude}
                    type="number"
                    label="Longitude"
                    variant="outlined"
                    size="small"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setLongitude(e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ px: 2, py: 2 }}>
          <Button sx={{ mr: 1 }} variant="text" onClick={onClickClose}>
            Close
          </Button>
          <MsButton
            loading={loading}
            label="Save"
            onClick={() =>
              onClickSave(
                createDistributor.name,
                createDistributor.type,
                latitude,
                longitude,
                createDistributor.upline_distributor_id
              )
            }
            variant="contained"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
