//google maps key
export const MAP_API_KEY = "AIzaSyDQV9pEIwhEXW7WsHGhRExE31Ov6vM4vXI";

//qr regex
export const QR_BALI = "QR_BALI";
export const AGG_BALI = "AGG_BALI";
export const QR_MALANG = "QR_MALANG";
export const AGG_MALANG = "AGG_MALANG";

//api auth
export const API_URL_AUTH = process.env.REACT_APP_API_AUTH;
export const REALM = process.env.REACT_APP_REALM;

//api list
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_AGGREGATION = process.env.REACT_APP_API_URL_AGGREGATION;
export const API_URL_ITEM = process.env.REACT_APP_API_MS_ITEM;
export const API_URL_SECURE = process.env.REACT_APP_API_MS_SECURE;

//authentification
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE;

//per page distributor dialog
export const PER_PAGE_DIALOG_DISTRIBUTOR = 5;
