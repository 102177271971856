import { createTheme } from "@mui/material/styles";

//theme
const theme = createTheme({
  palette: {
    primary: {
      light: "#70C9CD",
      main: "#164D5A",
      dark: "#103D4D",
      contrastText: "#fff",
    },
    secondary: {
      light: "#9CD5D5",
      main: "#416D75",
      dark: "#2F5864",
      contrastText: "#fff",
    },
    success: {
      main: "#1D932F",
      dark: "#287A18",
      contrastText: "#fff",
    },
    warning: {
      main: "#CEAD06",
      dark: "#CEAD06",
    },
    error: {
      main: "#8C1616",
      dark: "#8C1616",
    },
  },
});

export default theme;
