import { TrackerModel } from "@/domain/model/tracker";
import { TrackerInt } from "@repository/interface/tracker";
import { BaseRepo } from "@repository/base_repo";

export class Tracker implements TrackerInt {
  track(data: any): any {
    return new Promise((resolve, reject) => {
      BaseRepo.post("/track/qr/fetch", data)
        .then((result) => {
          let trackResult = TrackerModel.senderReceiver(result.data);
          resolve(trackResult);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
