//react
import { Outlet } from "react-router-dom";

//material
import { Box } from "@mui/material";

//component
import { AppBarTop } from "@delivery/components/Layout/AppBar/AppBar";
import { DrawerSide } from "@delivery/components/Layout/Drawer/Drawer";

export function MainView() {
  return (
    <Box sx={{ display: "flex" }}>
      <AppBarTop />
      <DrawerSide />
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 10 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
