import { AuthInt } from "@/repository/interface/auth";
import { BaseRepo } from "@/repository/base_repo";

export class Auth implements AuthInt {
  login(data: any) {
    return new Promise((resolve, reject) => {
      BaseRepo.login_post(data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
