import { AggregationModel } from "@/domain/model/aggregation";
import { AggregationQrModel } from "@/domain/model/aggregation_qr";
import { AggregationInt } from "@/repository/interface/aggregation";
import { BaseRepoMsAggregation } from "@/repository/base_repo_ms_aggregation";

export class Aggregation implements AggregationInt {
  fetchQrAssociation(data: any) {
    return new Promise<any>((resolve, reject) => {
      BaseRepoMsAggregation.get("/aggregation/qr/fetch-qr/", data)
        .then((res) => {
          let aggObj = {
            actualTotal: res.data.actual_total,
            aggregation: AggregationModel.fromJson(res.data.aggregation),
            aggregationQr: AggregationQrModel.fromJsonList(
              res.data.qr_aggregated
            ),
          };
          resolve(aggObj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // qrCheck(data: any){
  //   return new Promise ((resolve, reject) => {
  //     BaseRepo.get('')
  //   })
  // }
}
