import { DistributorModel } from "@/domain/model/distributor";
import { DistributorTypeModel } from "@/domain/model/distributor_type";
import { DistributorInt } from "@repository/interface/distributor";
import { BaseRepo } from "@/repository/base_repo";

export class Distributor implements DistributorInt {
  storeDistributor(data: any): any {
    return new Promise((resolve, reject) => {
      BaseRepo.post("/distributor/store", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  fetchDistributor(data: any): any {
    return new Promise((resolve, reject) => {
      BaseRepo.post("/distributor/fetch", data)
        .then((result) => {
          let distributorObj = {
            distributor: DistributorModel.fromJsonList(result.data.result),
            pagination: result.data.pagination,
          };
          resolve(distributorObj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getDistributor(data: any): any {
    return new Promise((resolve, reject) => {
      BaseRepo.post("/distributor/get", data)
        .then((result) => {
          let distributtorGetObj = DistributorModel.fromJson(result.data);
          resolve(distributtorGetObj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  fetchType(): any {
    return new Promise((resolve, reject) => {
      BaseRepo.get("/distributor/type")
        .then((result) => {
          let distributorTypeObj = DistributorTypeModel.fromJsonFromList(
            result.data
          );
          resolve(distributorTypeObj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  fetchUser(data: any): any {}
}
