//material
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";

//hook
import useSendProductSenderReceiver from "@/store/hook/send_product_sender_receiver";

//delivery
import { ListAggregation } from "@/delivery/components/AggregationList/AggregationList";
import { MsButton } from "@/delivery/components/Button/Button";
import { MsLoading } from "@/delivery/components/MsLoading/MsLoading";
import { QrList } from "@/delivery/components/QrList/QrList";
import { MsSnackBar } from "@/delivery/components/SnackBar/SnackBar";
import { MsTextField } from "@/delivery/components/TextField/TextField";
import { DialogDistributor } from "@/delivery/view/SendProduct/DialogDistributor";

//icon
import SearchIcon from "@mui/icons-material/Search";

export function SendProductSenderReceiver() {
  const {
    disabled,
    qrList,
    handleSelectSender,
    handleSelectReceiver,
    distributors,
    loadingSenderDialog,
    loadingReceiverDialog,
    handleDistributorDialog,
    setShowSenderDialogDistributor,
    showSenderDialogDistributor,
    setShowReceiverDialogDistributor,
    showReceiverDialogDistributor,
    handleOnCloseAlert,
    alert,
    aggregationList,
    loading,
    UUID,
    setUUID,
    handleAddUUID,
    handleSendProduct,
    setDistributionUniqueID,
    senderDistributorSelected,
    receiverDistributorSelected,
    loadingSubmit,
    handleRemoveQrList,
    handleRemoveQr,
    handleChangePage,
    page,
    loadingNextPage,
  } = useSendProductSenderReceiver();

  return (
    <>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={3000}
        onClose={handleOnCloseAlert}
      />
      <Typography sx={{ fontWeight: "bold" }} variant="h5">
        Distribute Product
      </Typography>

      <Grid item container sx={{ py: 2 }}>
        <Grid pr={1} item xs={12} md={6} lg={6}>
          <p>Distributor</p>
          {/* sender distributor */}
          <LoadingButton
            id={"sender"}
            startIcon={<SearchIcon />}
            loading={loadingSenderDialog}
            style={{
              justifyContent: "flex-start",
              textTransform: "none",
              fontSize: "1rem",
            }}
            onClick={(e) => handleDistributorDialog(e.currentTarget.id)}
            disableRipple
            fullWidth
            variant="outlined"
          >
            {senderDistributorSelected !== ""
              ? `${senderDistributorSelected}`
              : `Sender Distributor`}
          </LoadingButton>

          {/* receiver distributor */}
          <LoadingButton
            sx={{ mt: 1 }}
            id="receiver"
            startIcon={<SearchIcon />}
            loading={loadingReceiverDialog}
            style={{
              justifyContent: "flex-start",
              textTransform: "none",
              fontSize: "1rem",
            }}
            onClick={(e) => handleDistributorDialog(e.currentTarget.id)}
            disableRipple
            fullWidth
            variant="outlined"
          >
            {receiverDistributorSelected !== ""
              ? `${receiverDistributorSelected}`
              : `Receiver Distributor`}
          </LoadingButton>

          <MsTextField
            onChange={(e) => setDistributionUniqueID(e.target.value)}
            label={"Distribution Unique ID"}
            variant={"outlined"}
            size="small"
          />
        </Grid>
        {/* right side */}
        <Grid pl={1} item xs={12} md={6} lg={6}>
          <p>Scan Qr</p>
          <MsTextField
            spaceY={false}
            size="small"
            label="Qr Code"
            helperText="put cursor here to scan"
            value={UUID}
            onChange={(e) => setUUID(e.target.value)}
            onKeyEnter={handleAddUUID}
          />
          <Grid sx={{ height: "10px" }}>
            <MsLoading variant="linear" show={loading} />
          </Grid>
          <Grid item sx={{ py: 2 }}>
            <QrList
              handleRemoveQr={(index: any) => handleRemoveQr(index)}
              items={qrList}
            />
            <ListAggregation
              onClickRemoveList={(index: any) => handleRemoveQrList(index)}
              items={aggregationList}
            />
          </Grid>
          <Grid item sx={{ py: 2 }}>
            <MsButton
              disabled={disabled}
              loading={loadingSubmit}
              label="Submit"
              variant="contained"
              size="medium"
              fullWidth
              color="primary"
              onClick={handleSendProduct}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* sender dialog */}
      <DialogDistributor
        loading={loadingNextPage}
        onSelect={handleSelectSender}
        dataDistributor={distributors}
        onClose={() => setShowSenderDialogDistributor(false)}
        show={showSenderDialogDistributor}
        onChangePagePagination={(e: unknown, p: number) =>
          handleChangePage(e, p)
        }
        page={page}
      />

      {/* receiver dialog */}
      <DialogDistributor
        loading={loadingNextPage}
        onSelect={handleSelectReceiver}
        dataDistributor={distributors}
        onClose={() => setShowReceiverDialogDistributor(false)}
        show={showReceiverDialogDistributor}
        onChangePagePagination={(e: unknown, p: number) =>
          handleChangePage(e, p)
        }
        page={page}
      />
    </>
  );
}
