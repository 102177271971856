//react
import React, { useEffect, useState } from "react";

//material
import Typography from "@mui/material/Typography";
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//component
import { MsSnackBar } from "@/delivery/components/SnackBar/SnackBar";
import { MsButton } from "@/delivery/components/Button/Button";

//hook
import useAuth from "@/store/hook/auth";

export function Login() {
  const { setDataLogin, snackbar, dataLogin, submitLogin, loading } = useAuth();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [disabled, setDisabled] = useState(false);

  //validation
  useEffect(() => {
    if (dataLogin.username === "" || dataLogin.password === "") {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [dataLogin]);

  return (
    <div
      style={{
        backgroundImage: `url("https://urbangroup.co.id/wp-content/uploads/2022/11/layered-waves-haikei.png")`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        sx={{ px: 3, py: 3 }}
        style={{ minHeight: "100vh" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
        <Grid item justifySelf={"center"} xs={12} sm={4} md={4} lg={4}>
          <MsSnackBar
            severity="error"
            open={snackbar.snackbar}
            message={snackbar.message}
          />
          <Grid item container justifyContent={"center"}>
            <img
              src={
                "https://urbangroup.co.id/wp-content/uploads/2020/03/urban-company-logo.png"
              }
              width={"200px"}
              alt={"Urban Company"}
            />
          </Grid>

          <Typography align="center" sx={{ my: 3 }} variant="h6">
            Distribution
          </Typography>
          {/*username*/}
          <Grid sx={{ my: 1 }} item md={12} lg={12} xs={12}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              value={dataLogin.username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDataLogin({ ...dataLogin, username: e.target.value })
              }
              label="Username"
              fullWidth
              id="fullWidth"
            ></TextField>
          </Grid>

          {/*password*/}
          <Grid sx={{ my: 1 }} item xs={12} md={12} lg={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                value={dataLogin.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDataLogin({ ...dataLogin, password: e.target.value })
                }
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    submitLogin();
                    ev.preventDefault();
                  }
                }}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>

          {/*button login*/}
          <Grid sx={{ mt: 3 }} item xs={12} md={12} lg={12}>
            <MsButton
              disabled={disabled}
              textBold
              loading={loading}
              loadingPosition="center"
              label="Login"
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              onClick={submitLogin}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
      </Grid>
    </div>
  );
}
