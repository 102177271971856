//react
import { useState } from "react";

//constanta
import {
  AGG_BALI,
  AGG_MALANG,
  QR_BALI,
  QR_MALANG,
} from "@/domain/constanta/setting";

//repository
import { QrProduct } from "@/repository/api/qr_product";
import { Aggregation } from "@repository/api/aggregation";

//infrastructure
import getUUID from "@/infrastructure/utils/get_uuid";
import checkQr from "@/infrastructure/utils/check_qr";

//domain
import { Alert } from "@/domain/interface/alert";

const useSendAggregateQr = () => {
  //form submit
  const [qrUUID, setQrUUID] = useState<any>([]);
  // ------ END FORM ------

  //loading
  const [loading, setLoading] = useState(false);
  const [aggregationList, setAggregationList] = useState<[] | any | undefined>(
    []
  );
  const [qrList, setQrList] = useState<any>([]);

  //add UUID
  const [UUID, setUUID] = useState("");

  //alert error
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });

  //--- END HOOK ---

  let agg = new Aggregation();
  let qrProductObj = new QrProduct();

  //--- END OBJ ----

  //remove qr list (close icon)
  const handleRemoveQrList = (index: any) => {
    aggregationList.splice(index, 1);

    setAggregationList([
      ...aggregationList.slice(0, index),
      ...aggregationList.slice(index, aggregationList.length),
    ]);

    qrUUID.splice(index, 1);
    setQrUUID([
      ...qrUUID.slice(0, index),
      ...qrUUID.slice(index, qrUUID.length),
    ]);
  };

  const handleRemoveQr = (index: any) => {
    qrList.splice(index, 1);
    setQrList([
      ...qrList.slice(0, index),
      ...qrList.slice(index, qrList.length),
    ]);

    qrUUID.splice(index, 1);
    qrUUID([...qrUUID.slice(0, index), ...qrUUID.slice(index, qrUUID.length)]);
  };

  //handle add aggregate send
  const handleAddUUID = (e: any) => {
    if (e.key === "Enter") {
      let resCheck = checkQr(UUID);

      setLoading(true);

      //check if aggregation bali
      if (resCheck === AGG_BALI) {
        agg
          .fetchQrAssociation(UUID)
          .then((response) => {
            let resAggregationQr: any[] = response.aggregationQr;
            // let aggregation: any = response.aggregation;

            //chack double if double insert push data
            for (let i = 0; i < aggregationList.length; i++) {
              if (
                response.aggregation.UUID ===
                aggregationList[i].aggregation.UUID
              ) {
                setAlert({
                  show: true,
                  message: "QR has been inserted",
                  severity: "error",
                });
                setUUID("");
                setLoading(false);
                return;
              }
            }

            //check product name by id
            // if (aggProduct.name === "") {
            //   let data = {
            //     id: response.productID,
            //   };
            //   itemObj
            //     .getItem(data)
            //     .then((res: any) => {
            //       setAggProduct({
            //         name: res.name,
            //         product_id: res.ID,
            //       });
            //     })
            //     .catch((err: any) => {});
            // }

            //check if same product
            // if (aggProduct.name !== "") {
            //   if (aggProduct.product_id !== response.productID) {
            //     setAlert({
            //       show: true,
            //       message: "Product may not be the same",
            //       severity: "error",
            //     });
            //     setLoading(false);
            //     return;
            //   }
            // }

            //push to list
            setAggregationList((prevData: any) => [...prevData, response]);
            for (let x = 0; x < resAggregationQr.length; x++) {
              setQrUUID((prevData: any) => [
                ...prevData,
                resAggregationQr[x].qrUUID,
              ]);
            }
            // setQrUUID((prevData: any) => [...prevData, aggregation.UUID]);
            setLoading(false);
            setUUID("");
            return;
          })
          .catch((error) => {
            if (error.response.status !== 200) {
              //PUSH QR LIST

              setAlert({
                show: true,
                message: error.response.data.message,
                severity: "error",
              });
            }

            setLoading(false);
          });
      }

      //check if qr bali
      if (resCheck === QR_BALI) {
        let newUUID = getUUID(UUID);
        qrProductObj
          .checkByUUID(newUUID)
          .then((response) => {
            let uniqID = response.uniqID;
            //check qr active
            if (response.status !== 10) {
              setAlert({
                show: true,
                message: "please active your QR before agregate",
                severity: "error",
              });
              setLoading(false);
              return;
            }

            //check list qr and aggregation
            //PUSH array qr list
            for (let i = 0; i < qrUUID.length; i++) {
              if (qrUUID[i] === newUUID) {
                setAlert({
                  show: true,
                  message: "QR has been inserted",
                  severity: "error",
                });
                setUUID("");
                setLoading(false);
                return;
              }
            }

            //check product name by id
            // if (aggProduct.name === "") {
            //   let data = {
            //     id: result.data.product_id,
            //   };
            //   getProduct(data)
            //     .then((res) => {
            //       setAggProduct({
            //         name: res.name,
            //         product_id: res.ID,
            //       });
            //     })
            //     .catch((err) => {});
            // }

            //check if same product
            // if (aggProduct.name !== "") {
            //   if (aggProduct.product_id !== result.data.product_id) {
            //     setAlert({
            //       show: true,
            //       message: "Product may not be the same",
            //       severity: "error",
            //     });
            //     setLoading(false);
            //     return;
            //   }
            // }

            setQrList((prevData: any) => [...prevData, uniqID]);
            setQrUUID((prevData: any) => [...prevData, newUUID]);
            setLoading(false);
            setUUID("");
            return;

            //error
            // setAlert({
            //   show: true,
            //   message:
            //     "Aggregated product and single product can't be on one aggreagtion",
            //   severity: "error",
            // });

            // setLoading(false);
          })
          .catch((error) => {
            if (error.response.status !== 200) {
              setAlert({
                show: true,
                message: error.response.data.message,
                severity: "error",
              });
              setLoading(false);
              return;
            }
          });
      }

      //check if qr malang
      if (resCheck === QR_MALANG) {
        //do something
      }

      // check if aggregation malang
      if (resCheck === AGG_MALANG) {
        //do something
      }
    }
  };

  //handle close alert
  const handleOnCloseAlert = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  return {
    setAggregationList,
    setQrList,
    loading,
    UUID,
    handleRemoveQrList,
    handleAddUUID,
    aggregationList,
    qrList,
    setUUID,
    alert,
    setAlert,
    handleOnCloseAlert,
    handleRemoveQr,
    setQrUUID,
    qrUUID,
  };
};

export default useSendAggregateQr;
