//component
import Maps from "@/delivery/components/Maps/Maps";
import { MsTextField } from "@/delivery/components/TextField/TextField";

//model
import { DistributionModel } from "@/domain/model/distribution";
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";

//material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";

interface DialogDetailDistributionProps {
  show: boolean;
  onClickClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  items?: DistributionModel | any;
}

export function DialogDetailDistribution(props: DialogDetailDistributionProps) {
  const { show, onClickClose, items } = props;
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      scroll={"paper"}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Detail Distribution</DialogTitle>
      <DialogContent>
        <Box sx={{ px: 2, py: 2 }}>
          <MsTextField
            variant={"standard"}
            disabled
            label="UUID"
            value={items?.distribution.UUID}
          />
          <MsTextField
            variant={"standard"}
            disabled
            label="Date"
            value={changeTimeFormat(items?.distribution.createdAt)}
          />
          <MsTextField
            variant={"standard"}
            disabled
            label="Quantity"
            value={items?.distribution.total}
          />
          <MsTextField
            variant={"standard"}
            disabled
            label="Unique ID"
            value={items?.distribution.uniqueCode}
          />
          <Grid mt={2} container>
            <Grid item px={1} sm={6} md={6} lg={6}>
              Sender
              <MsTextField
                variant={"standard"}
                disabled
                label="UUID"
                value={items?.sender.UUID}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Name"
                value={items?.sender.name}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Address"
                value={`${items?.sender.cityName}, ${items?.sender.provinceName}`}
              />
              <Maps
                lat={items?.sender.latitude}
                long={items?.sender.longitude}
              />
            </Grid>
            <Grid item px={1} sm={6} md={6} lg={6}>
              Receiver
              <MsTextField
                variant={"standard"}
                disabled
                label="UUID"
                value={items?.receiver.UUID}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Name"
                value={items?.receiver.name}
              />
              <MsTextField
                variant={"standard"}
                disabled
                label="Address"
                value={`${items?.receiver.cityName}, ${items?.sender.provinceName}`}
              />
              <Maps
                lat={items?.receiver.latitude}
                long={items?.receiver.longitude}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClickClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
