import { BaseModel } from "@domain/model/base_model";
import { DistributionProductsModel } from "@domain/model/distribution_products";

export class DistributionModel extends BaseModel {
  uniqueCode: string;
  total: number;
  distributorUUID: number;
  distributorReceiverUUID: number;
  userID: number;
  distributionProducts?: DistributionProductsModel[];

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    uniqueCode: string,
    total: number,
    distributorUUID: number,
    distributorReceiverUUID: number,
    userID: number,
    distributionProducts?: DistributionProductsModel[]
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.ID = ID;
    this.UUID = UUID;
    this.createdAt = createdAt;
    this.updateAt = updateAt;
    this.uniqueCode = uniqueCode;
    this.total = total;
    this.distributorUUID = distributorUUID;
    this.distributorReceiverUUID = distributorReceiverUUID;
    this.userID = userID;
    this.distributionProducts = distributionProducts;
  }

  public static fromJsonList(data: any): DistributionModel[] | any {
    try {
      let listDistribution: DistributionModel[];
      listDistribution = [];
      for (let x = 0; x < data.length; x++) {
        listDistribution.push(DistributionModel.fromJson(data[x]));
      }
      return listDistribution;
    } catch {
      return null;
    }
  }

  public static fromJson(data: any): DistributionModel | any {
    try {
      return new DistributionModel(
        data.id,
        data.uuid,
        data.created_at,
        data.updated_at,
        data.unique_code,
        data.total,
        data.distributor_uuid,
        data.distributor_receiver_uuid,
        data.user_id,
        DistributionModel.fromJsonList(data.distribution_products)
      );
    } catch {
      return null;
    }
  }
}
