import { BaseModel } from "@domain/model/base_model";
import { DistributionModel } from "@domain/model/distribution";

export class FraudDetectorModel extends BaseModel {
  distributionUUID: string;
  description: string;
  fraudType: string;
  qrUUID: string;
  distribution: DistributionModel;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    distributionUUID: string,
    description: string,
    fraudType: string,
    qrUUID: string,
    distribution: DistributionModel
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.ID = ID;
    this.UUID = UUID;
    this.createdAt = createdAt;
    this.updateAt = updateAt;
    this.distributionUUID = distributionUUID;
    this.description = description;
    this.fraudType = fraudType;
    this.qrUUID = qrUUID;
    this.distribution = distribution;
  }

  public static fromJson(data: any): FraudDetectorModel | any {
    try {
      return new FraudDetectorModel(
        data.id,
        data.uuid,
        data.created_at,
        data.updated_at,
        data.distribution_uuid,
        data.description,
        data.fraud_type,
        data.qr_uuid,
        DistributionModel.fromJson(data.distribution)
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any) {
    try {
      let fraudDetectorList: FraudDetectorModel[];
      fraudDetectorList = [];
      for (let x = 0; x < data.length; x++) {
        fraudDetectorList.push(FraudDetectorModel.fromJson(data[x]));
      }
      return fraudDetectorList;
    } catch {
      return null;
    }
  }
}
