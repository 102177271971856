//react
import { useEffect, useState } from "react";

//model
import { DistributorModel } from "@/domain/model/distributor";

//repository
import { Distributor } from "@/repository/api/distributor";
import { Distribution } from "@/repository/api/distribution";

//hook
import useSendAggregateQr from "@/store/hook/send_aggregate_qr";

//constanta
import { PER_PAGE_DIALOG_DISTRIBUTOR } from "@/domain/constanta/setting";

const useSendProductSenderReceiver = () => {
  const {
    loading,
    setAggregationList,
    setQrList,
    handleAddUUID,
    UUID,
    setUUID,
    handleRemoveQrList,
    aggregationList,
    qrList,
    setAlert,
    alert,
    handleOnCloseAlert,
    handleRemoveQr,
    setQrUUID,
    qrUUID,
  } = useSendAggregateQr();

  //form submit
  const [senderDistributorUUID, setSenderDistributorUUID] = useState("");
  const [receiverDistributorUUID, setReceiverDistributorUUID] = useState("");
  const [distributionUniqueID, setDistributionUniqueID] = useState("");
  // ------ END FORM ------

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  //sender
  const [showSenderDialogDistributor, setShowSenderDialogDistributor] =
    useState<boolean>(false);
  const [senderDistributorSelected, setSenderDistributorSelected] =
    useState("");
  const [loadingSenderDialog, setLoadingSenderDialog] = useState(false);

  //receiver
  const [showReceiverDialogDistributor, setShowReceiverDialogDistributor] =
    useState<boolean>(false);
  const [receiverDistributorSelected, setReceiverDistributorSelected] =
    useState("");
  const [loadingReceiverDialog, setLoadingReceiverDialog] = useState(false);

  const [distributors, setDistributors] = useState<DistributorModel[]>();

  const [disabled, setDisabled] = useState(false);

  //dialog pick distributor
  const [page, setPage] = useState<number>(1);
  const [loadingNextPage, setLoadingNextPage] = useState(false);

  // --- END HOOK ---

  let distributorObj = new Distributor();
  let distributionObj = new Distribution();
  //--- END OBJ ----

  //show dialog pick distributor
  const handleDistributorDialog = (e: any) => {
    if (e === "sender") {
      setLoadingSenderDialog(true);
      let params = {
        id: null,
        uuid: null,
        upline_distributor_id: null,
        limit: PER_PAGE_DIALOG_DISTRIBUTOR,
        page: 1,
      };

      distributorObj
        .fetchDistributor(params)
        .then((result: any) => {
          setDistributors(result);
          setLoadingSenderDialog(false);
          setShowSenderDialogDistributor(true);
        })
        .catch(() => {
          setLoadingSenderDialog(false);
        });
    }

    if (e === "receiver") {
      setLoadingReceiverDialog(true);
      let params = {
        id: null,
        uuid: null,
        upline_distributor_id: null,
        limit: PER_PAGE_DIALOG_DISTRIBUTOR,
        page: 1,
      };

      distributorObj
        .fetchDistributor(params)
        .then((result: any) => {
          setDistributors(result);
          setLoadingReceiverDialog(false);
          setShowReceiverDialogDistributor(true);
        })
        .catch(() => {
          setLoadingReceiverDialog(false);
        });
    }
  };

  //handle change page
  const handleChangePage = (e: unknown, p: number) => {
    setLoadingNextPage(true);
    setPage(p);
    let params = {
      id: null,
      uuid: null,
      upline_distributor_id: null,
      limit: PER_PAGE_DIALOG_DISTRIBUTOR,
      page: p,
    };

    distributorObj
      .fetchDistributor(params)
      .then((result: any) => {
        setLoadingNextPage(false);
        setDistributors(result);
      })
      .catch(() => {});
  };

  //handle select sender
  const handleSelectSender = (item: any) => {
    setSenderDistributorUUID(item.UUID);
    setSenderDistributorSelected(item.name);
    setShowSenderDialogDistributor(false);
  };

  //handle select receiver
  const handleSelectReceiver = (item: any) => {
    setReceiverDistributorUUID(item.UUID);
    setReceiverDistributorSelected(item.name);
    setShowReceiverDialogDistributor(false);
  };

  //submit distribute/send product
  const handleSendProduct = () => {
    setLoadingSubmit(true);
    let params = {
      sender_uuid: senderDistributorUUID,
      receiver_uuid: receiverDistributorUUID,
      qr_uuid: qrUUID,
      distribution_unique_id: distributionUniqueID,
    };
    distributionObj
      .sendQrProduct(params)
      .then((res: any) => {
        setAlert({
          message: "Send Product Successfully",
          severity: "success",
          show: true,
        });
        setInterval(() => {
          window.location.reload();
        }, 1000);
        setLoadingSubmit(false);
      })
      .catch((error: any) => {
        setAlert({
          message: error.response.data.message,
          severity: "error",
          show: true,
        });
        setLoadingSubmit(false);
      });
  };

  // -- useEffect --

  useEffect(() => {
    if (aggregationList.length === 0 && qrList.length === 0) {
      setQrUUID([]);
    }
  }, [qrList, aggregationList]);

  //validation form
  useEffect(() => {
    if (
      senderDistributorUUID === "" ||
      receiverDistributorUUID === "" ||
      distributionUniqueID === "" ||
      qrUUID.length === 0
    ) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [
    senderDistributorUUID,
    receiverDistributorUUID,
    distributionUniqueID,
    qrUUID,
  ]);

  return {
    disabled,
    qrList,
    setQrList,
    handleSelectSender,
    handleSelectReceiver,
    distributors,
    loadingSenderDialog,
    loadingReceiverDialog,
    handleDistributorDialog,
    setShowSenderDialogDistributor,
    showSenderDialogDistributor,
    setShowReceiverDialogDistributor,
    showReceiverDialogDistributor,
    handleOnCloseAlert,
    alert,
    setAlert,
    setAggregationList,
    aggregationList,
    loading,
    UUID,
    setUUID,
    handleAddUUID,
    handleSendProduct,
    setDistributionUniqueID,
    senderDistributorSelected,
    receiverDistributorSelected,
    loadingSubmit,
    setLoadingSubmit,
    handleRemoveQrList,
    handleRemoveQr,
    handleChangePage,
    page,
    loadingNextPage,
  };
};

export default useSendProductSenderReceiver;
