import { BaseModel } from "@domain/model/base_model";
// import { Product } from "./product";
// import { AggregationHistory } from "./aggregationHistory";

export class AggregationModel extends BaseModel {
  qty: number;
  label: string;
  userId: string;
  productId: string;
  aggregationParentUUID?: string;
  aggregationRefUUID?: string;
  status: string;
  level: number;
  subAggregation: AggregationModel[];
  //   product?: Product[];
  //   aggregationHistory?: AggregationHistory[];

  constructor(
    ID: bigint,
    UUID: string,
    createdAt: string,
    updateAt: string,
    qty: number,
    label: string,
    userId: string,
    productId: string,
    aggregationParentUUID: string,
    aggregationRefUUID: string,
    status: string,
    level: number,
    subAggregation: []
    // product: Product[],
    // aggregationHistory: AggregationHistory[]
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.subAggregation = subAggregation;
    this.qty = qty;
    this.label = label;
    this.userId = userId;
    this.productId = productId;
    this.aggregationParentUUID = aggregationParentUUID;
    this.aggregationRefUUID = aggregationRefUUID;
    this.status = status;
    this.level = level;
    // this.product = product;
    // this.aggregationHistory = aggregationHistory;
  }

  public static fromJsonToList(data: any): AggregationModel[] | any {
    //if params null
    if (data == null || data === undefined) {
      return null;
    }

    //if params not null
    let listAggregation: AggregationModel[] = [];
    for (let i = 0; i < data.length; i++) {
      listAggregation.push(AggregationModel.fromJson(data[i]));
    }
    return listAggregation;
  }

  public static fromJson(data: any): AggregationModel | any {
    try {
      return new AggregationModel(
        data.id,
        data.uuid,
        data.created_at,
        data.update_at,
        data.qty,
        data.label,
        data.user_id,
        data.product_id,
        data.aggregation_parent_uuid,
        data.aggregation_ref_uuid,
        data.status,
        data.level,
        data.sub_aggregation
        // Product.fromJson(data.product),
        // AggregationHistory.fromJsonToList(data.aggregation_history)
      );
    } catch {
      return null;
    }
  }
}
