import Maps from "@/delivery/components/Maps/Maps";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface DialogMapsDetailProps {
  items?: any;
  show: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function DialogMapsDetail(props: DialogMapsDetailProps) {
  const { items, show, onClose } = props;

  return (
    <>
      <Dialog fullWidth open={show} maxWidth={"md"}>
        <DialogTitle>{"Maps Detail"}</DialogTitle>
        <DialogContent>
          <Maps lat={items.lat} long={items.long} width={"100%"} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
