//react
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//repository
import { Auth } from "@repository/api/auth";

//infrastructure
import { setToken } from "@/infrastructure/utils/auth_utils";

//constanta
import {
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE,
} from "@/domain/constanta/setting";

const useAuth = () => {
  //obj
  let authObj = new Auth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ snackbar: false, message: "" });
  const [dataLogin, setDataLogin] = useState({ username: "", password: "" });

  const submitLogin = () => {
    setLoading(true);
    let data = {
      client_id: CLIENT_ID,
      username: dataLogin.username,
      password: dataLogin.password,
      grant_type: GRANT_TYPE,
      client_secret: CLIENT_SECRET,
    };
    authObj
      .login(data)
      .then((res: any) => {
        setToken(res.data.access_token);
        navigate("/admin/send-product");
        window.location.reload();
        setLoading(false);
      })
      .catch((error) => {
        setSnackbar({
          message: error.response.data.error_description,
          snackbar: true,
        });
        setLoading(false);
      });
  };

  return {
    setDataLogin,
    dataLogin,
    setSnackbar,
    snackbar,
    submitLogin,
    loading,
    setLoading,
  };
};

export default useAuth;
