import { API_URL, API_URL_AUTH, REALM } from "@/domain/constanta/setting";
import axiosClient from "@/infrastructure/axios/axios";

export class BaseRepo {
  public static async post(url: string, data: any) {
    return await axiosClient({
      baseURL: `${API_URL}`,
      method: "POST",
      url: `${url}`,
      data: data,
    });
  }

  public static async get(url: string, data?: any) {
    return await axiosClient({
      baseURL: `${API_URL}`,
      method: "GET",
      url: `${url}`,
      data: data,
    });
  }
  public static async put(url: string, data: any) {
    return await axiosClient({
      baseURL: `${API_URL}`,
      method: "PUT",
      url: `${url}`,
      data: data,
    });
  }

  //login
  public static async login_post(data: any) {
    var objectToQueryString = function (obj: any) {
      return Object.keys(obj)
        .map(function (key) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
        })
        .join("&");
    };

    return await axiosClient({
      baseURL: `${API_URL_AUTH}`,
      method: "POST",
      url: `/realms/${REALM}/protocol/openid-connect/token`,
      data: objectToQueryString(data),
    });
  }

  // public static async get_agg(url: string, data: any) {
  //   return await axios({
  //     method: "GET",
  //     url: `${API_URL_AGGREGATION}${url}${data}`,
  //     headers: {
  //       Authorization: `${localStorage.getItem("token")}`,
  //     },
  //   });
  // }
}
