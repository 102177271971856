//react
import { useEffect, useState } from "react";

//model
import { DistributionModel } from "@/domain/model/distribution";

//repository
import { Distribution } from "@repository/api/distribution";

const useDistribution = () => {
  let distributionObj = new Distribution();
  const [loading, setLoading] = useState(false);

  //distribution
  const [distributions, setDistributions] = useState<DistributionModel[]>();
  const [distribution, setDistribution] = useState<DistributionModel>();
  const [pagination, setPagination] = useState();

  //pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  //dialog distribution
  const [showDialogDistribution, setShowDialogDistribution] = useState(false);

  //mounted ms distribution
  useEffect(() => {
    setLoading(true);
    let params = {
      id: null,
      uuid: null,
      unique_code: null,
      total: null,
      distributor_id: null,
      distributor_receiver_id: null,
      limit: 10,
      sort: "Id desc",
      page: 1,
    };

    distributionObj
      .fetchDistribution(params)
      .then((res: any) => {
        setDistributions(res.distribution);
        setPagination(res.pagination);
        setTotalCount(res.pagination.total_rows);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  //detail distribution
  const handleDetailDistribution = (UUID: string) => {
    setLoading(true);
    let params = {
      id: null,
      uuid: UUID,
      unique_code: null,
      total: null,
      distributor_id: null,
      distributor_receiver_id: null,
    };

    distributionObj
      .getDistribution(params)
      .then((result: any) => {
        setDistribution(result);
        setShowDialogDistribution(true);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  // --PAGINATION--

  //change page
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setLoading(true);
    setPage(newPage);

    let params = {
      limit: rowsPerPage,
      sort: "Id desc",
      page: newPage + 1,
    };

    distributionObj
      .fetchDistribution(params)
      .then((res: any) => {
        setDistributions(res.distribution);
        setPagination(res.pagination);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //rows per page
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLoading(true);
    //change page
    let perPage = parseInt(event.target.value);

    setLoadingData(true);
    setPage(0);
    setRowsPerPage(perPage);

    let params = {
      limit: perPage,
      sort: "Id desc",
      page: page,
    };

    distributionObj
      .fetchDistribution(params)
      .then((res: any) => {
        setDistributions(res.distribution);
        setPagination(res.pagination);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return {
    page,
    totalCount,
    rowsPerPage,
    handleChangePage,
    handleRowsPerPageChange,
    pagination,
    loading,
    distribution,
    distributions,
    showDialogDistribution,
    setShowDialogDistribution,
    handleDetailDistribution,
  };
};

export default useDistribution;
