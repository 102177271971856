import { BaseModel } from "@domain/model/base_model";

export class DistributorTypeModel extends BaseModel {
  name: string;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    name: string
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.ID = ID;
    this.UUID = UUID;
    this.createdAt = createdAt;
    this.name = name;
  }

  public static fromJsonFromList(data: any) {
    try {
      let listDistributorType: DistributorTypeModel[];
      listDistributorType = [];
      for (let x = 0; x < data.length; x++) {
        listDistributorType.push(DistributorTypeModel.fromJson(data[x]));
      }
      return listDistributorType;
    } catch {
      return null;
    }
  }

  public static fromJson(data: any): DistributorTypeModel | any {
    try {
      return new DistributorTypeModel(
        data.id,
        data.uuid,
        data.created_at,
        data.updated_at,
        data.name
      );
    } catch {
      return null;
    }
  }
}
