import { BaseModel } from "@domain/model/base_model";
import { DistributorTypeModel } from "@domain/model/distributor_type";

export class DistributorModel extends BaseModel {
  uplineDistributorID: number;
  name: string;
  typeID: number;
  latitude: number;
  longitude: number;
  fullAddress: string;
  nationalID: number;
  nationalCode: string;
  nationalName: string;
  provinceID: number;
  provinceName: string;
  cityID: number;
  cityName: string;
  districtID: number;
  districtName: string;
  villageID: number;
  villageName: string;
  zipCode: number;
  distributorType?: DistributorTypeModel;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    uplineDistributorID: number,
    name: string,
    typeID: number,
    latitude: number,
    longitude: number,
    fullAddress: string,
    nationalID: number,
    nationalCode: string,
    nationalName: string,
    provinceID: number,
    provinceName: string,
    cityID: number,
    cityName: string,
    districtID: number,
    districtName: string,
    villageID: number,
    villageName: string,
    zipCode: number,
    distributorType: DistributorTypeModel
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.ID = ID;
    this.UUID = UUID;
    this.createdAt = createdAt;
    this.updateAt = updateAt;
    this.uplineDistributorID = uplineDistributorID;
    this.name = name;
    this.typeID = typeID;
    this.latitude = latitude;
    this.longitude = longitude;
    this.fullAddress = fullAddress;
    this.nationalID = nationalID;
    this.nationalCode = nationalCode;
    this.nationalName = nationalName;
    this.provinceID = provinceID;
    this.provinceName = provinceName;
    this.cityID = cityID;
    this.cityName = cityName;
    this.districtID = districtID;
    this.districtName = districtName;
    this.villageID = villageID;
    this.villageName = villageName;
    this.zipCode = zipCode;
    this.distributorType = distributorType;
  }

  public static fromJsonList(data: any): DistributorModel[] | any {
    try {
      let listDistributor: DistributorModel[];
      listDistributor = [];
      for (let x = 0; x < data.length; x++) {
        listDistributor.push(DistributorModel.fromJson(data[x]));
      }
      return listDistributor;
    } catch {
      return null;
    }
  }

  public static fromJson(data: any): DistributorModel | null | any {
    try {
      return new DistributorModel(
        data.id,
        data.uuid,
        data.created_at,
        data.updated_at,
        data.upline_distributor_id,
        data.name,
        data.type_id,
        data.latitude,
        data.longitude,
        data.full_address,
        data.national_id,
        data.national_code,
        data.national_name,
        data.province_id,
        data.province_name,
        data.city_id,
        data.city_name,
        data.district_id,
        data.district_name,
        data.village_id,
        data.village_name,
        data.zip_code,
        DistributorTypeModel.fromJson(data.distributor_type)
      );
    } catch {
      return null;
    }
  }
}
