//react
import { useEffect, useState } from "react";

//domain
import { Alert } from "@/domain/interface/alert";
import { DistributorModel } from "@/domain/model/distributor";
import { DistributorTypeModel } from "@/domain/model/distributor_type";

//repository
import { Distributor } from "@/repository/api/distributor";

const useDistributor = () => {
  let distributorObj = new Distributor();

  //alert
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });

  //loading
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  //distribution
  const [distributors, setDistributors] = useState<DistributorModel[]>();
  const [distributor, setDistributor] = useState<DistributorModel>();
  const [distributorType, setDistributorType] =
    useState<DistributorTypeModel[]>();
  const [pagination, setPagination] = useState();

  //distributor dialog
  const [showDialogDistributor, setShowDialogDistributor] = useState(false);
  const [showDialogCreateDistributor, setShowDialogCreateDistributor] =
    useState(false);

  //pagination hook
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  //pagination function
  const handleOnPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // change page
    setPage(newPage);
    setLoading(true);
    let params = {
      id: null,
      uuid: null,
      upline_distributor_id: null,
      limit: rowsPerPage,
      page: newPage + 1,
    };
    distributorObj
      .fetchDistributor(params)
      .then((result: any) => {
        setDistributors(result.distributor);
        setPagination(result.pagination);
        setTotalCount(result.pagination.total_rows);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //handle change row per page
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    //change page
    let perPage = parseInt(event.target.value);
    //
    setRowsPerPage(perPage);
    setPage(0);
    setLoading(true);
    let params = {
      id: null,
      uuid: null,
      upline_distributor_id: null,
      limit: perPage,
      page: page + 1,
    };
    distributorObj
      .fetchDistributor(params)
      .then((result: any) => {
        setDistributors(result.distributor);
        setPagination(result.pagination);
        setTotalCount(result.pagination.total_rows);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // --- END PAGINATION FUNCTION ---

  //mounted distributor
  useEffect(() => {
    setLoading(true);
    let params = {
      id: null,
      uuid: null,
      upline_distributor_id: null,
      limit: 10,
      page: 1,
    };
    distributorObj
      .fetchDistributor(params)
      .then((result: any) => {
        setDistributors(result.distributor);
        setPagination(result.pagination);
        setTotalCount(result.pagination.total_rows);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  //detail table distributor
  const handleDetailDistributor = (UUID: string) => {
    setLoading(true);
    let params = {
      id: null,
      uuid: UUID,
      upline_distributor_id: null,
    };
    distributorObj
      .getDistributor(params)
      .then((result: any) => {
        setDistributor(result);
        setShowDialogDistributor(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //handle show dialog create and fetch type
  const handleShowDialogCreateDistributor = () => {
    let distParams = {
      id: null,
      uuid: null,
      upline_distributor_id: null,
    };
    distributorObj
      .fetchType()
      .then((resultType: any) => {
        distributorObj
          .fetchDistributor(distParams)
          .then((resultDist: any) => {
            setDistributorType(resultType);
            setDistributors(resultDist.distributor);
            setPagination(resultDist.pagination);
            setShowDialogCreateDistributor(true);
          })
          .catch((err: any) => {});
      })
      .catch((err: any) => {});
  };

  //save distributor
  const handleOnClickSaveDistributor = (
    name: string,
    type: string,
    latitude: string,
    longitude: string,
    upline_distributor_id: string
  ) => {
    setLoadingSave(true);

    let params = {
      name: name,
      type_id: parseInt(type),
      latitude: latitude,
      longitude: longitude,
      upline_distributor_id: parseInt(upline_distributor_id),
    };

    let paramsFetch = {
      id: null,
      uuid: null,
      upline_distributor_id: null,
    };

    distributorObj
      .storeDistributor(params)
      .then((result: any) => {
        setAlert({
          show: true,
          message: " Create Distributor Successfully",
          severity: "success",
        });
        setLoadingSave(false);
        setShowDialogCreateDistributor(false);

        //fetch distributor
        setLoading(true);
        distributorObj
          .fetchDistributor(paramsFetch)
          .then((result: any) => {
            setDistributors(result.distributor);
            setPagination(result.pagination);
            setTotalCount(result.pagination.total_rows);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch((error: any) => {
        setAlert({
          show: true,
          message: error.response.data.message,
          severity: "error",
        });
        setLoadingSave(false);
      });
  };

  return {
    pagination,
    alert,
    setAlert,
    distributor,
    distributors,
    loading,
    loadingSave,
    page,
    handleOnPageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    totalCount,
    handleDetailDistributor,
    showDialogDistributor,
    setShowDialogDistributor,
    showDialogCreateDistributor,
    setShowDialogCreateDistributor,
    handleOnClickSaveDistributor,
    handleShowDialogCreateDistributor,
    distributorType,
  };
};

export default useDistributor;
