import { resolve } from "path";
import { BaseRepo } from "@/repository/base_repo";
import { FraudDetectorInt } from "@repository/interface/fraud_detector";

//const
import { FETCH_DISTRIBUTION_FRAUD } from "@/domain/constanta/api_endpoint";
import { FraudDetectorModel } from "@/domain/model/fraud_detector";

export class FraudDetector implements FraudDetectorInt {
  fetchDistributionFraud(data: any) {
    return new Promise((resolve, reject) => {
      BaseRepo.post(FETCH_DISTRIBUTION_FRAUD, data)
        .then((result) => {
          let fraudDetectorObj = FraudDetectorModel.fromJsonList(result.data);
          resolve(fraudDetectorObj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
