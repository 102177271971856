import jwtDecode from "jwt-decode";
import { getProfile } from "@infrastructure/utils/auth_utils";

export class AccessPermission {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  static keyCloakRule(dataToken: any) {
    let decodedToken = jwtDecode(dataToken);
    return decodedToken;
  }

  public static checkAccess(roles: string[]): boolean | any {
    //from token
    let allow: boolean;
    allow = false;
    let claim: any = getProfile();
    roles.forEach((role: any) => {
      let permittedRole = role.split(".");
      let appRole = permittedRole[0];
      let accessRole: string = permittedRole[1];

      //source access
      let sourceAccess = claim["resource_access"];
      let jwtSource = sourceAccess[appRole];

      if (jwtSource != null) {
        jwtSource.roles.forEach((access: string) => {
          if (access === accessRole) {
            allow = true;
          }
        });
      }
      return allow;
    });
    return allow;
  }
}
