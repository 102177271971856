//react
import React, { useEffect, useState } from "react";

//material
import { Typography, Grid, AppBar, Toolbar, IconButton } from "@mui/material";

//icon
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

//infrastructure
import useAppBar from "@/store/hook/app_bar";
import { DialogConfirmLogout } from "@delivery/components/Layout/AppBar/DialogConfirmLogout";

export function AppBarTop() {
  const { profile, handleLogout, dialogConfirmLogout, setDialogConfirmLogout } =
    useAppBar();

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{ flexGrow: 1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Grid sx={{ mr: 2 }}>
            <img
              src={
                "https://urbangroup.co.id/wp-content/uploads/2020/03/urban-company-logo-putih.png"
              }
              width={"100px"}
              alt={"Urban Company"}
            />
          </Grid>
          <Typography
            variant="subtitle1"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            | Distribution
          </Typography>
          <Typography variant="subtitle1" noWrap component="div">
            {profile?.name}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setDialogConfirmLogout(true)}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogConfirmLogout
        onClicCancel={() => setDialogConfirmLogout(false)}
        onClickYes={handleLogout}
        show={dialogConfirmLogout}
      />
    </div>
  );
}
