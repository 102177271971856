import { BaseModel } from "@domain/model/base_model";

export class QRProductModel extends BaseModel {
  status: string;
  productID: number;
  uniqID: string;
  serialNumber: number;
  serialCode: string;
  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    status: string,
    productID: number,
    uniqID: string,
    serialNumber: number,
    serialCode: string
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.status = status;
    this.productID = productID;
    this.uniqID = uniqID;
    this.serialNumber = serialNumber;
    this.serialCode = serialCode;
  }

  public static fromJson(data: any): QRProductModel | any {
    try {
      return new QRProductModel(
        data.id,
        data.uuid,
        data.created_at,
        data.update_at,
        data.status,
        data.product_id,
        data.uniq_id,
        data.serial_number,
        data.serial_code
      );
    } catch {
      return null;
    }
  }
}
