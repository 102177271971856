//material
import { Grid, Typography } from "@mui/material";

//lottie
import Lottie from "lottie-react";
import Radar from "@/delivery/assets/98501-radar.json";

//hook
import useTracker from "@/store/hook/tracker";

//delivery
import { TimeLineTracker } from "@delivery/view/Tracker/TimeLineTracker";
import { MsLoading } from "@/delivery/components/MsLoading/MsLoading";
import { MsSnackBar } from "@/delivery/components/SnackBar/SnackBar";
import { MsTextField } from "@/delivery/components/TextField/TextField";

export function Tracker() {
  const {
    trackerUUID,
    setTrackerUUID,
    handleGetTracker,
    trackerRes,
    loading,
    alert,
    handleOnCloseAlert,
  } = useTracker();

  return (
    <Grid>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={3000}
        onClose={handleOnCloseAlert}
      />
      <Typography sx={{ fontWeight: "bold" }} variant="h5">
        Track Product
      </Typography>
      <Grid container sx={{ pt: 4 }}>
        <Grid item md={8} sm={8} lg={8}>
          <MsTextField
            value={trackerUUID}
            size="small"
            helperText="put cursor here on scan or press 'Enter'"
            label="Qr Code"
            variant="outlined"
            onChange={(e) => setTrackerUUID(e.target.value)}
            onKeyEnter={(ev) => {
              if (ev.key === "Enter") {
                handleGetTracker();
                ev.preventDefault();
              }
            }}
          />
        </Grid>
        {/* <Grid item ml={1} mt={1} md={1} sm={1} lg={1}>
          <MsLoading variant="circular" show={loading} />
        </Grid> */}
      </Grid>
      <Grid container>
        {/* if loading */}
        {loading ? (
          <Grid
            container
            style={{
              minHeight: "600px",
              border: "3px",
              boxSizing: "border-box",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sx={{ width: "350px" }}>
              <Lottie animationData={Radar} loop={true} />
              <Typography
                align="center"
                sx={{ mt: 2, fontWeight: "light" }}
                variant="h5"
              >
                Tracking...
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid item md={8} sm={8} lg={8}>
            <TimeLineTracker items={trackerRes} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
