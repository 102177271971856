//material
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

//icon
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

//hook
import useDistributor from "@/store/hook/distributor";

//component
import { TableDistributor } from "@delivery/view/Distributor/TableDistributor";
import { MsButton } from "@/delivery/components/Button/Button";
import { DialogDetailDistributor } from "@delivery/view/Distributor/DialogDetailDistributor";
import { DialogCreateDistributor } from "@delivery/view/Distributor/DialogCreateDistributor";
import { MsSnackBar } from "@/delivery/components/SnackBar/SnackBar";

export function Distributor() {
  const {
    alert,
    setAlert,
    distributor,
    distributors,
    loading,
    loadingSave,
    page,
    handleOnPageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    totalCount,
    handleDetailDistributor,
    showDialogDistributor,
    setShowDialogDistributor,
    showDialogCreateDistributor,
    setShowDialogCreateDistributor,
    handleOnClickSaveDistributor,
    handleShowDialogCreateDistributor,
    distributorType,
    pagination,
  } = useDistributor();

  return (
    <Grid>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={3000}
        onClose={() => setAlert({ show: false, message: "", severity: "info" })}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            height={"100%"}
            display={"flex"}
            justifyContent="start"
            alignItems={"center"}
          >
            <Typography sx={{ fontWeight: "bold" }} variant="h5">
              Distributor
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            height={"100%"}
            display={"flex"}
            justifyContent="end"
            alignItems={"center"}
          >
            <MsButton
              sx={{ my: 1 }}
              startIcon={<AddCircleOutlineIcon />}
              color="primary"
              variant="text"
              label="Add Distributor"
              onClick={handleShowDialogCreateDistributor}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid sx={{ pt: 4 }}>
        <TableDistributor
          page={page}
          onRowsPerPageChange={(e) => handleRowsPerPageChange(e)}
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          onPageChange={handleOnPageChange}
          loading={loading}
          items={distributors}
          onDetailDistributor={(UUID: any) => handleDetailDistributor(UUID)}
        />
        <DialogDetailDistributor
          items={distributor}
          onClickClose={() => setShowDialogDistributor(false)}
          show={showDialogDistributor}
        />
        <DialogCreateDistributor
          loading={loadingSave}
          itemDistributors={distributors}
          itemsType={distributorType}
          show={showDialogCreateDistributor}
          onClickClose={() => setShowDialogCreateDistributor(false)}
          onClickSave={(
            name,
            type,
            latitude,
            longitude,
            upline_distributor_id
          ) =>
            handleOnClickSaveDistributor(
              name,
              type,
              latitude,
              longitude,
              upline_distributor_id
            )
          }
        />
      </Grid>
    </Grid>
  );
}
