//material design
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Stack,
  TablePagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

//component
import { MsLoading } from "@/delivery/components/MsLoading/MsLoading";

//model
import { DistributionModel } from "@/domain/model/distribution";

//utility
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";
import shortCharacter from "@/infrastructure/utils/short_character";

function createColumn(id: string | number, name: string) {
  return { id, name };
}

const columns = [
  createColumn(1, "UUID"),
  createColumn(2, "Unique Code"),
  createColumn(3, "Qty"),
  createColumn(4, "Date"),
  createColumn(5, "Action"),
];

interface TableDistributionProps {
  items?: DistributionModel[];
  loading?: boolean;
  onDetailDistribution: (UUID: string) => void;
  //
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  rowsPerPage: number;
  totalCount: number;
  page: number;
}

export function TableDistribution(props: TableDistributionProps) {
  const {
    onDetailDistribution,
    items,
    loading,
    totalCount,
    page,
    onPageChange,
    onRowsPerPageChange,
    rowsPerPage,
  } = props;

  return (
    <>
      <MsLoading variant="linear" show={loading} />
      <TableContainer variant="outlined" component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#eceef0" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item: any) => (
              <TableRow key={item.ID}>
                <TableCell align="left">
                  {shortCharacter(item.UUID, 13)}
                </TableCell>
                <TableCell align="left">{item.uniqueCode}</TableCell>
                <TableCell align="left">{item.total}</TableCell>
                <TableCell align="left">
                  {changeTimeFormat(item.createdAt)}
                </TableCell>
                <TableCell align="left">
                  <LoadingButton
                    sx={{ mr: 1 }}
                    size="small"
                    disableElevation
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      onDetailDistribution(item.UUID);
                    }}
                  >
                    Detail
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Stack sx={{ py: 2 }} spacing={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={totalCount}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Stack>
      </Grid>
    </>
  );
}
