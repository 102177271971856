import { BaseModel } from "@domain/model/base_model";

export class AggregationQrModel extends BaseModel {
  aggregationUUID: string;
  qrUUID: string;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    aggregationUUID: string,
    qrUUID: string
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.aggregationUUID = aggregationUUID;
    this.qrUUID = qrUUID;
  }

  public static fromJsonList(data: any): AggregationQrModel[] {
    let aggreagtionQrList: AggregationQrModel[] = [];
    for (let i = 0; i < data.length; i++) {
      aggreagtionQrList.push(AggregationQrModel.fromJson(data[i]));
    }
    return aggreagtionQrList;
  }

  public static fromJson(data: any): AggregationQrModel {
    return new AggregationQrModel(
      data.id,
      data.uuid,
      data.created_at,
      data.update_at,
      data.aggregation_uuid,
      data.qr_uuid
    );
  }
}
