//component
import { MsLoading } from "@/delivery/components/MsLoading/MsLoading";

//model
import { FraudDetectorModel } from "@/domain/model/fraud_detector";

// utility
import shortCharacter from "@/infrastructure/utils/short_character";

//material
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Stack } from "@mui/system";

function createColumn(id: string | number, name: string) {
  return { id, name };
}

const columns = [
  createColumn(1, "UUID"),
  createColumn(2, "Fraud Type"),
  createColumn(3, "Description"),
];

interface TableDistributorFraudProps {
  items?: FraudDetectorModel[];
  loading?: boolean;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  totalCount: number;
  page: number;
  rowsPerPage: number;
}

export function TableDistributorFraud(props: TableDistributorFraudProps) {
  return (
    <div>
      <MsLoading variant="linear" show={props.loading} />
      <TableContainer variant="outlined" component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#eceef0" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items?.map((item: any) => (
              <TableRow key={item.ID}>
                <TableCell align="left">
                  {shortCharacter(item.qrUUID, 13)}
                </TableCell>
                <TableCell align="left">{item.fraudType}</TableCell>
                <TableCell align="left">{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Stack sx={{ py: 2 }} spacing={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={props.totalCount}
            page={props.page}
            onPageChange={props.onPageChange}
            rowsPerPage={props.rowsPerPage}
            onRowsPerPageChange={props.onRowsPerPageChange}
          />
        </Stack>
      </Grid>
    </div>
  );
}
