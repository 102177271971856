//react
import { useState } from "react";

//domain
import { Alert } from "@/domain/interface/alert";

//infrastructure
import getUUID from "@/infrastructure/utils/get_uuid";

//repository
import { Tracker } from "@/repository/api/tracker";

const useTracker = () => {
  //tracker obj
  let trackObj = new Tracker();
  const [trackerUUID, setTrackerUUID] = useState("");
  const [trackerRes, setTrackerRes] = useState([]);
  const [loading, setLoading] = useState(false);
  //alert
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });

  //handle get tracker
  const handleGetTracker = () => {
    setLoading(true);

    let newTrackerUUID = getUUID(trackerUUID);

    let trackerParams = {
      qr_uuid: newTrackerUUID,
    };
    trackObj
      .track(trackerParams)
      .then((result: any) => {
        setTrackerRes(result);
        setLoading(false);
        setTrackerUUID("");
      })
      .catch((error: any) => {
        setLoading(false);
        setAlert({
          message: error.response.data.message,
          severity: "error",
          show: true,
        });
      });
  };

  //handle on close alert
  const handleOnCloseAlert = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  return {
    trackerUUID,
    handleGetTracker,
    setTrackerUUID,
    trackerRes,
    loading,
    alert,
    handleOnCloseAlert,
  };
};

export default useTracker;
