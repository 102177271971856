import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { MAP_API_KEY } from "@/domain/constanta/setting";

interface MapsProps {
  lat: number | undefined | any;
  long: number | undefined | any;
  zoomVal?: number;
  height?: number | string;
  width?: number | string;
}

function Maps(props: MapsProps) {
  const { lat, long, zoomVal, height, width } = props;

  const containerStyle = {
    width: width ? width : "400px",
    height: height ? height : "400px",
  };

  // center maps
  const center = {
    lat: lat,
    lng: long,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    map.setZoom(15);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onMarkerClick = (e: any) => {
    console.log(e.latLng.lat());
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker
        key={"marker_1"}
        position={{
          lat: lat,
          lng: long,
        }}
        onClick={(e) => onMarkerClick(e)}
      />
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Maps);
