import { LoadingButton } from "@mui/lab";
import { SxProps, Theme } from "@mui/material";
import React from "react";

interface MsButtonProps {
  loading?: boolean;
  loadingPosition?: "start" | "center" | "end" | undefined;
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: "text" | "outlined" | "contained" | undefined;
  label?: string;
  size?: "small" | "medium" | "large" | undefined;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  sx?: SxProps<Theme> | undefined;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disableMargin?: boolean;
  textBold?: boolean;
  disabled?: boolean;
}

export function MsButton(props: MsButtonProps) {
  return (
    <LoadingButton
      disabled={props.disabled}
      sx={props.sx}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      size={props.size}
      loading={props.loading}
      loadingPosition={props.loadingPosition}
      fullWidth={props.fullWidth}
      variant={props.variant}
      disableElevation
      onClick={props.onClick}
      color={props.color}
    >
      {props.textBold ? <b>{props.label}</b> : props.label}
    </LoadingButton>
  );
}
